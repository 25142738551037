import axios from 'axios'
import { formItemContextKey } from 'element-plus';

/***
 * 获取版本
 */
export function getVersionData( data:any ){
   return axios.get("/settingservice/systems/version/pagination", { params: data });
}

/***
 * 更新版本信息
 */
export function addVersionData( data:any ){
   return axios.post(`/settingservice/systems/version`, data);
}


/***
 * 删除版本信息
 */
export function removeVersionData( data:any ){
   return axios.delete(`/settingservice/systems/version`, {params: data})
}


/**
 * 获取指定平台的版本列表
 */
export function getPlatformVersionList( data:any ){
   return axios.get(`/settingservice/systems/version/platform/list`, { params: data });
}



/***
 * 获取用户反馈列表
 */
export function getFeedbackPagination( data:any ){
   return axios.get("/settingservice/systems/feedback/pagination", { params: data });
}


/***
 * 删除反馈内容
 */
export function removeFeedback( data:any ){
   return axios.delete(`/settingservice/systems/feedback/${data.id}`, data);
}


/***
 * 获取轮播图列表
 */
export function getBannerList( data:any ){
   return axios.get("/settingservice/banners/list", { params: data });
}

/**
 * 新增轮播图
 */
export function addBanner( data:any ){
   return axios.post("/settingservice/banners", data);
}

/**
 * 删除轮播图
 */
export function removeBanner( data:any ){
   return axios.delete(`/settingservice/banners/${data.id}`, data);
}

/***
 * 图片上传oss接口
 */
export function getOssSignature( data:any ){
   return axios.get(`/servicesupport/oss/policy`, data);
}

/***
 * 图片上传到 oss
 */
export function uploadOss( url:string,  data:any ) {

      return axios.post( url, data );
}